import { useEffect } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

interface ModalProps {
  children?: React.ReactNode;
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<any>>;
}

const Modal = ({ children, show, setModalShow }: ModalProps) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        setModalShow(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [show]);

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity bg-black bg-opacity-50"
              onClick={handleClose}
            ></div>
            <div className="z-10 w-full overflow-hidden bg-white rounded-lg shadow-xl max-w-fit">
            <div className="relative">
                <button
                  className="absolute z-50 p-2 text-base transition duration-500 transform pointer-events-auto w-fit h-fit top-2 right-2 hover:scale-150 "
                  onClick={handleClose}
                >
                  <VscChromeClose />
                </button>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
