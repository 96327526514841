import { useState, useEffect } from 'react';
import { FaKeyboard } from 'react-icons/fa';
import { IoRefreshOutline } from 'react-icons/io5';
import { getADViewCount, putADViewCount } from 'api/dashboard/ad-views';
import ViewChart from 'components/Dashboard/ADView/ADViewChart';
import ViewTable from 'components/Dashboard/ADView/ADViewTable';

interface ViewData {
  date: string;
  total_count: number;
  image_count: number;
  video_count: number;
}

function ViewCount() {
  const [viewCountList, setViewCountList] = useState<ViewData[]>([]);
  const [viewCountSortList, setViewCountSortList] = useState<ViewData[]>([]);
  const [isKeyPressActivated, setIsKeyPressActivated] = useState(false);

  const featureId = '28535';

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 49) {
        performAction(featureId, 'video');
      } else if (event.keyCode === 50) {
        performAction(featureId, 'image');
      }
    };

    if (isKeyPressActivated) {
      window.addEventListener('keydown', handleKeyPress);
    } else {
      window.removeEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isKeyPressActivated, featureId]);

  const toggleActivation = () => {
    setIsKeyPressActivated(!isKeyPressActivated);
  };

  const setData = (data: ViewData[]) => {
    const originalData: ViewData[] = [...data];
    const sortedData = data.sort((a: ViewData, b: ViewData) => a.date.localeCompare(b.date));
    setViewCountList(originalData);
    setViewCountSortList(sortedData);
  };
  
  const performAction = (featureId: string, type: string) => {
    putADViewCount(featureId, type).then((res) => {
      setData(res.data);
    });
  };
  
  const fetchData = () => {
    getADViewCount(featureId).then((res) => {
      setData(res.data);
    });
  };

  return (
    <div>
      <div
        className="absolute rounded-lg btn top-0 left-[10.5rem] text-lg min-w-fit h-8 min-h-[1.5rem] px-1.5 border border-gray-200 bg-gray-200 hover:bg-gray-300 hover:border-gray-300 hover:shadow"
        onClick={fetchData}
      >
        <IoRefreshOutline />
      </div>
      <div className="flex mb-3 text-gray-800 text-lg justify-between">
        <div className="flex items-center">
          <div className="flex items-center mr-6">
            <span className="font-bold text-xl text-gray-900">구역ID:</span>
            <span className="ml-1 text-gray-600">28535</span>
          </div>
          <div className="flex items-center">
            <span className="font-bold text-xl text-gray-900">구역명:</span>
            <span className="ml-1 text-gray-600">연세로 10-1</span>
          </div>
        </div>
        <div className="relative">
          <button
            className={`rounded-lg text-lg min-w-fit h-8 min-h-[1.5rem] px-1.5 border flex items-center transition-colors ${
              isKeyPressActivated
                ? 'bg-red-500 text-white shadow hover:bg-red-600'
                : 'bg-gray-200 text-gray-700 hover:bg-red-500'
            }  hover:text-white`}
            onClick={toggleActivation}
          >
            <FaKeyboard />
            {/* {isKeyPressActivated ? <span className="ml-2">키입력 활성화</span> : ''} */}
          </button>
          {/* {isKeyPressActivated && (
            <div className="absolute text-base z-10 p-2 right-0 bottom-full mb-2 text-gray-800 bg-white rounded-lg shadow w-[200px] border border-gray-300 text-center">
              <p>1번 - 비디오 조회수 증가</p>
              <p>2번 - 이미지 조회수 증가</p>
            </div>
          )} */}
        </div>
      </div>
      <ViewChart viewCountList={viewCountSortList} />
      <ViewTable viewCountList={viewCountList} />
    </div>
  );
}

export default ViewCount;
