import { useSearchParams } from 'react-router-dom';
import { includesByCho } from 'hangul-util';

type FilterProps = {
  sort: string | null;
  filterList: any[];
  handleSorting: (key: string, param: string) => void;
};

const formatDate = (date: Date) => {
  var yyyy = date.getFullYear();
  var MM = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  var dd = ('0' + date.getDate()).slice(-2);

  return yyyy + MM + dd;
};

export default function useFilter(list: any[]): FilterProps {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort');
  
  let filterList: any[] = list;

  const handleSorting = (key: string, param: string) => {
    searchParams.delete('page');

    if (param === 'DATE') {
      if (!sort) {
        searchParams.set('sort', `${param}_ASC`);
      } else if (sort === `${param}_ASC`) {
        searchParams.set('sort', `${param}_DESC`);
      } else {
        searchParams.delete('sort');
      }
      setSearchParams(searchParams);

      if (sort === `${param}_ASC`) {
        filterList = [
          ...list.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          ),
        ];
      } else if (sort === `${param}_DESC`) {
        filterList = [
          ...list.sort(
            (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          ),
        ];
      }
    } else {
      if (!sort) {
        searchParams.set('sort', `${param}_ASC`);
      } else if (sort === `${param}_ASC`) {
        searchParams.set('sort', `${param}_DESC`);
      } else {
        searchParams.delete('sort');
      }
      setSearchParams(searchParams);

      if (sort === `${param}_ASC`) {
        filterList = [...list.sort((a, b) => b[key] - a[key])];
      } else if (sort === `${param}_DESC`) {
        filterList = [...list.sort((a, b) => a[key] - b[key])];
      }
    }
  };
  
  const filterByParam = (param: string, callback: (data: any) => boolean) => {
    const paramData = searchParams.get(param);
    filterList = filterList.filter((data) => (paramData ? callback(data) : true));
  };

  filterByParam('email', (data) => data.email.toLowerCase().includes(searchParams.get('email')?.toLowerCase() || ''));
  filterByParam('name',(data) => data.feature_name.includes(searchParams.get('name') || '') || includesByCho(searchParams.get('name') || '', data.feature_name));
  filterByParam('role', (data) => data.role === searchParams.get('role'));
  filterByParam('start_date', (data) => {
    const date = formatDate(new Date(data.created_at));
    return date >= searchParams.get('start_date')! && date <= searchParams.get('end_date')!
  })
  
  return { sort, filterList, handleSorting };
}

