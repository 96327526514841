import React from 'react';
import Router from 'router';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration:2000,
          style: {
            maxWidth: 500,
          },
        }}
      />
      <Router />
    </>
  );
}
export default App;
