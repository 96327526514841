import { FormEvent, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoSearchSharp } from 'react-icons/io5';
import Datepicker from 'react-tailwindcss-datepicker';

const formatDate = (date: Date) => {
  var yyyy = date.getFullYear();
  var MM = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  var dd = ('0' + date.getDate()).slice(-2);

  return yyyy + MM + dd;
};

export default function TransactionFilter() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchName, setSearchName] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
    },
  ]);

  useEffect(() => {
    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      searchParams.delete('page');

      searchParams.set('start_date', formatDate(new Date(selectedDateRange.startDate)));
      searchParams.set('end_date', formatDate(new Date(selectedDateRange.endDate)));

      setSearchParams(searchParams);
    } else {
      searchParams.delete('start_date');
      searchParams.delete('end_date');

      setSearchParams(searchParams);
    }
  }, [selectedDateRange]);

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    searchParams.delete('page');

    if (searchName !== '') {
      searchParams.set('name', searchName);
    } else {
      searchParams.delete('name');
    }
    setSearchParams(searchParams);
  };

  const handleDateSelect = (ranges: any) => {
    setSelectedDateRange(ranges);
  };

  const clearFilter = () => {
    setSearchName('');
    setSelectedDateRange({
      startDate: null,
      endDate: null,
    });
    navigate('.', { replace: true, state: {} });
  };

  return (
    <div className="flex items-center justify-between mb-3">
      <div className="flex items-center">
        <form
          className="relative flex flex-row flex-wrap items-center w-72"
          onSubmit={handleSearch}
        >
          <div className="join">
            <label htmlFor="search" className="sr-only">
              검색창
            </label>
            <input
              type="text"
              id="search"
              placeholder="구역명 검색"
              className="relative py-2 pl-3 pr-10 text-sm text-gray-600 transition-all bg-white border border-r-0 border-gray-200 rounded shadow outline-none placeholder-neutral-400 join-item w-60 h-9 focus:outline-none focus:border-neutral-300 focus:border-2 focus:border-r-0"
              onChange={(e) => setSearchName(e.target.value)}
              value={searchName}
            />
            <button
              type="submit"
              className="top-0 z-10 items-center justify-center p-0 text-base font-normal leading-snug text-center bg-transparent bg-white border border-gray-200 rounded shadow join-item w-9 min-h-fit btn-circle btn right-2 h-9"
            >
              <IoSearchSharp />
            </button>
          </div>
        </form>
        <Datepicker
          i18n={'ko'}
          primaryColor={'fuchsia'}
          containerClassName="relative"
          inputClassName="px-4 py-2 text-sm leading-tight text-gray-700 bg-white border border-gray-200 rounded shadow appearance-none w-72 h-9 focus:outline-none focus:bg-white focus:border-neutral-300 focus:border-2"
          placeholder={'날짜선택'}
          value={selectedDateRange}
          onChange={handleDateSelect}
          displayFormat={'YYYY/MM/DD'}
          readOnly={true}
          showShortcuts={true}
          configs={{
            shortcuts: {
              today: '오늘',
              yesterday: '어제',
              past: (period) => `지난 ${period}일`,
              currentMonth: '이번 달',
              pastMonth: '지난 달',
            },
          }}
        />
      </div>
      <button
        type="button"
        className=" ml-3 btn min-h-[2.25rem] h-9 rounded shadow border-gray-200"
        onClick={clearFilter}
      >
        초기화
      </button>
    </div>
  );
}
