import Layout from 'components/Layout/Layout';
import TransactionTable from 'components/Transaction/TransactionTable';
import TransactionFilter from 'components/Transaction/TransactionFilter';

function TransactionPage() {
  return (
    <Layout title="매매관리">
      <>
        <TransactionFilter />
        <TransactionTable />
      </>
    </Layout>
  );
}

export default TransactionPage;
