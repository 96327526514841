import { useState, useEffect, useRef } from 'react';
import usePagination from 'hooks/usePagination';
import Pagination from 'components/common/Pagination';
import { useRecoilState } from 'recoil';
import { memberListState } from 'atoms/memberStates';
import { UserType } from 'types/auth.types';
import useFilter from 'hooks/useFilter';
import { BiDotsVerticalRounded } from 'react-icons/bi';
// import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

import MemberEditModal from './MemberEditModal';
import MemberPasswordEditModal from './MemberPasswordEditModal';
import SandMailModal from './SandMailModal';
import { getMemberList } from 'api/member';

function MemberTable() {
  const focusRef = useRef<HTMLDivElement | null>(null);
  const [memberList, setMemberList] = useRecoilState(memberListState);
  const { filterList } = useFilter(memberList);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [userData, setUserData] = useState<UserType | null>(null);
  const { pages, goPrev, goNext, goPageNum, lastPage, currentPage, startIdx, lastIdx } =
    usePagination(filterList.length, 10, 5);

  useEffect(() => {
    getMemberList().then((res) => {
      setMemberList(res);
    });
  }, [modalShow]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (focusRef.current && !focusRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menus = [
    {
      type: 'info-edit',
      name: '회원정보수정',
      component: (
        <MemberEditModal userData={userData} show={modalShow} setModalShow={setModalShow} />
      ),
    },
    {
      type: 'password-edit',
      name: '비밀번호수정',
      component: (
        <MemberPasswordEditModal userData={userData} show={modalShow} setModalShow={setModalShow} />
      ),
    },
    {
      type: 'sand-mail',
      name: '인증메일발송',
      component: <SandMailModal userData={userData} show={modalShow} setModalShow={setModalShow} />,
    },
  ];

  const editBtn = (data: UserType, type: string) => {
    setModalShow(true);
    setUserData(data);
    setShowOptions(false);
    setModalType(type);
  };

  const handleClickSubMenu = (data: UserType) => {
    if (userData?.id === data.id) {
      setShowOptions(!showOptions);
    } else {
      setShowOptions(true);
    }
    setUserData(data);
  };

  return (
    <div className="py-2">
      <div className="min-h-[620px]">
        <table className="table text-center border rounded-lg shadow">
          <thead className="text-base bg-gray-100">
            <tr>
              {/* <th
                  onClick={handleSorting}
                >
                  <div className="flex items-center justify-center">
                    <span className="mr-1">No.</span>
                    {sort === 'ID_ASC' ? (
                      <TiArrowSortedDown className="icon" />
                    ) : (
                      <TiArrowSortedUp className="icon" />
                    )}
                  </div>
                </th> */}
              <th>이메일</th>
              <th>닉네임</th>
              <th>권한</th>
              <th>포인트</th>
              <th>상태</th>
              <th>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filterList && filterList.length !== 0 ? (
              filterList.slice(startIdx, lastIdx + 1).map((data: UserType, i) => (
                  <tr key={data.id}>
                  {/* <td> {i + 1}</td> */}
                  <td className='w-[30%]'>{data.email}</td>
                  <td className='w-[15%]'>{data.nickname}</td>
                  <td className='w-[10%]'>{data.role}</td>
                  <td className='w-[15%]'>{data.balance && data.balance.toLocaleString('ko-KR')}</td>
                  <td className='w-[10%]'>
                    {data.is_active ? (
                      <span className="text-base text-blue-500 text-blod">정상</span>
                    ) : (
                      <span className="text-base text-red-500 text-blod">비활성</span>
                    )}
                  </td>
                  <td className="text-right">
                    <div className="relative">
                      <button
                        type="button"
                        className="text-base btn btn-ghost btn-circle btn-sm hover:bg-neutral-200"
                        onClick={() => handleClickSubMenu(data)}
                      >
                        <BiDotsVerticalRounded />
                      </button>
                      {showOptions && userData?.id === data.id ? (
                        <div ref={focusRef}>
                          <ul
                            className={`min-w-[130px] absolute right-8 z-50 bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg -bottom-[70px] transition text-left`}
                          >
                            {menus.map((menu, i) => {
                              return (
                                <li key={menu.type}>
                                  <button
                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    onClick={() => {
                                      editBtn(data, menu.type);
                                    }}
                                  >
                                    {menu.name}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12} className="py-5 text-neutral-400">
                  조건에 맞는 검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {modalShow &&
        menus.map((menu, i) => {
          return modalType === menu.type ? <div key={i}>{menu.component}</div> : null;
        })}

      <Pagination
        pages={pages}
        goPrev={goPrev}
        goNext={goNext}
        goPageNum={goPageNum}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export default MemberTable;
