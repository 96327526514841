import { useState, useEffect } from 'react';
import usePagination from 'hooks/usePagination';
import Pagination from 'components/common/Pagination';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { IoRefreshOutline } from 'react-icons/io5';
import useFilter from 'hooks/useFilter';

import { getTradeHistory } from 'api/area';

const formatDate = (date: Date) => {
  var yyyy = date.getFullYear();
  var MM = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  var dd = ('0' + date.getDate()).slice(-2);
  var HH = ('0' + date.getHours()).slice(-2);
  var mm = ('0' + date.getMinutes()).slice(-2);

  return yyyy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm;
};

function TransactionTable() {
  const [transactionList, setTransactionList] = useState<any[]>([]);
  const { sort, filterList, handleSorting } = useFilter(transactionList!);
  const { pages, goPrev, goNext, goPageNum, lastPage, currentPage, startIdx, lastIdx } =
    usePagination(filterList.length, 10, 5);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getTradeHistory().then((res) => {
      setTransactionList(res.data);
    });
  };

  return (
    <div className="py-2">
      <div
        className="absolute rounded-lg btn top-0 left-[5.5rem] text-lg min-w-fit h-8 min-h-[1.5rem] px-1.5 border border-gray-200 bg-gray-200 hover:bg-gray-300 hover:border-gray-300 hover:shadow"
        onClick={fetchData}
      >
        <IoRefreshOutline />
      </div>
      <div className="min-h-[620px]">
        <table className="table text-center border rounded-lg shadow">
          <thead className="text-base bg-gray-100">
            <tr>
              {/* <th onClick={() => handleSorting('id','ID')}>
              <div className="flex items-center justify-center">
                <span className="mr-1">No.</span>
                {sort === 'ID_ASC' ? (
                  <TiArrowSortedDown className="icon" />
                ) : sort === 'ID_DESC' ? (
                  <TiArrowSortedUp className="icon" />
                ) : (
                  <TiArrowUnsorted className="icon" />
                )}
              </div>
            </th> */}
              <th>구역명</th>
              <th onClick={() => handleSorting('trade_price', 'POINT')}>
                <div className="flex items-center justify-center">
                  <span className="mr-1">거래가격</span>
                  {sort === 'POINT_ASC' ? (
                    <TiArrowSortedDown className="icon" />
                  ) : sort === 'POINT_DESC' ? (
                    <TiArrowSortedUp className="icon" />
                  ) : (
                    <TiArrowUnsorted className="icon" />
                  )}
                </div>
              </th>
              <th>판매자</th>
              <th>구매자</th>
              <th onClick={() => handleSorting('created_at', 'DATE')}>
                <div className="flex items-center justify-center">
                  <span className="mr-1">날짜</span>
                  {sort === 'DATE_ASC' ? (
                    <TiArrowSortedDown className="icon" />
                  ) : sort === 'DATE_DESC' ? (
                    <TiArrowSortedUp className="icon" />
                  ) : (
                    <TiArrowUnsorted className="icon" />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filterList && filterList.length !== 0 ? (
              filterList.slice(startIdx, lastIdx + 1).map((data) => (
                <tr key={data.id} className="h-14">
                  {/* <td>{data.id}</td> */}
                  <td className='w-[20%]'>{data.feature_name}</td>
                  <td className='w-[10%]'>{data.trade_price && data.trade_price.toLocaleString('ko-KR')}</td>
                  <td className='w-[20%]'>{data.seller_email === 'init@system' ? '소유권 없음' : data.seller_email}</td>
                  <td className='w-[20%]'>{data.buyer_email}</td>
                  <td className='w-[20%]'>{formatDate(new Date(data.created_at))}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12} className="py-5 text-neutral-400">
                  조건에 맞는 검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pages={pages}
        goPrev={goPrev}
        goNext={goNext}
        goPageNum={goPageNum}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export default TransactionTable;
