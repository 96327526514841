import usePagination from 'hooks/usePagination';
import Pagination from 'components/common/Pagination';

const ViewTable = ({ viewCountList }: any) => {
  const { pages, goPrev, goNext, goPageNum, lastPage, currentPage, startIdx, lastIdx } =
    usePagination(viewCountList.length, 7, 5);
  return (
    <>
      <div className="min-h-[365px] mt-10">
        <table className="table overflow-hidden text-center border rounded-lg shadow ">
          <thead className="text-base bg-gray-100">
            <tr>
              <th>날짜</th>
              <th>비디오 조회수</th>
              <th>이미지 조회수</th>
              <th>총 조회수</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {viewCountList &&
              viewCountList.slice(startIdx, lastIdx + 1).map((data: any, i: any) => (
                <tr key={i}>
                  <td className="w-[10%]">{data.date}</td>
                  <td className="w-[10%]">{data.video_count}</td>
                  <td className="w-[10%]">{data.image_count}</td>
                  <td className="w-[10%]">{data.total_count}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        pages={pages}
        goPrev={goPrev}
        goNext={goNext}
        goPageNum={goPageNum}
        lastPage={lastPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default ViewTable;
