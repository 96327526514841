import axios from 'axios';
import { API } from 'config';
import { UserType } from 'types/auth.types';

async function getMemberList() {
  try {
    const res = await axios.get(API.USERS);
    return res.data.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function patchMember(userData: UserType) {
  try {
    const data = {
      nickname: userData.nickname,
      role: userData.role,
      is_active: userData.is_active,
      balance: userData.balance,
    } as UserType;

    const res = await axios.patch(API.USERS + userData.id, data);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

// 비밀번호 재설정 메일 발송
async function sandEmailAPI(email: string) {
  try {
    const res = await axios.post(`${API.PASSWORD_RECOVERY}/${email}`);

    return res.data;
  } catch (err) {
    console.log('change password sand email err', err);
    throw err;
  }
}

async function pwdResetAPI(id: string, password: string) {
  try {
    const res = await axios.post(API.RESET_PASSWORD, {
      id : id,
      new_password: password,
    });
    return res.data;
  } catch (err) {
    console.log('change password err', err);
    throw err;
  }
}

export { getMemberList, patchMember, sandEmailAPI, pwdResetAPI };
