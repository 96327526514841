import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-3 shadow-md rounded-md text-gray-800">
        <p className="font-semibold text-sm">날짜 : {label}</p>
        <p className="text-xs">총 조회수 : {payload[0].payload.total_count}</p>
        <p className="text-xs">비디오 조회수 : {payload[1].value}</p>
        <p className="text-xs">이미지 조회수 : {payload[0].value}</p>
      </div>
    );
  }

  return null;
};
function ViewChart({ viewCountList }: any) {
  const recentData = viewCountList.slice(-14); // 최근 14개의 데이터만 선택

  const formatDate = (date: string) => {
    return date.substring(5);
  };

  return (
    <div className="h-screen max-h-96">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={200}
          height={300}
          data={recentData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatDate} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
            <Legend
              formatter={(value, entry, index) =>
                value === 'image_count' ? '이미지 조회수' : '비디오 조회수'
              }
            />
          <Bar
            dataKey="video_count"
            stackId="a"
            fill="#8884d8"
            isAnimationActive={true}
            animationDuration={1500}
          />
          <Bar
            dataKey="image_count"
            stackId="a"
            fill="#68C3D6"
            isAnimationActive={true}
            animationDuration={1500}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ViewChart;
