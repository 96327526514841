import { Cookies } from 'react-cookie';

const cookies = new Cookies();

// Key, Value를 받아 Cookie 생성 후 저장
export const setCookie = (
  key: string,
  value: string | {},
  option: { path: string; maxAge: number }
) => {
  return cookies.set(key, value, { ...option });
};

// Key를 받아 Value를 반환
export const getCookie = (key: string) => {
  return cookies.get(key);
};

// Key를 받아 쿠키를 삭제
export const removeCookie = (key: string) => {
  return cookies.remove(key);
};
