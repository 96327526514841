import axios from 'axios';
import { API } from 'config';

async function getADViewCount(feature_id: string) {
  try {
    // const url = `/data/view_count_dummy.json`;
    const url = `${API.DEMONSTRATE}/${feature_id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.error('get view count err', err);
  }
}

async function putADViewCount(feature_id: string, type: string) {
  try {
    const url = `${API.DEMONSTRATE}/${feature_id}?type=${type}`;
    const response = await axios.put(url);
    return response.data;
  } catch (err) {
    console.error('put view count err', err);
  }
}

export { getADViewCount, putADViewCount };