import { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { drawPolygonState, selectAreaState, mapState } from 'atoms/areaStates';
import { AreaType } from 'types/area.types';
import { authUserStates } from 'atoms/authStates';
import { UserType } from 'types/auth.types';
import { getFeatureName, putNewPolygon } from 'api/area';

function NewAreaInfo() {
  const featureNameRef = useRef<HTMLInputElement | null>(null);
  const [featureName, setFeatureName] = useState('');
  const [address, setAddress] = useState('');
  let [coords, setCoords] = useState<number[][]>([]);

  const map = useRecoilValue(mapState);
  const [drawPolygon, setDrawPolygon] = useRecoilState(drawPolygonState);
  const setAreaInfo = useSetRecoilState<AreaType | null>(selectAreaState);
  const [userData] = useRecoilState<UserType | null>(authUserStates);

  const removePolygon = () => {
    drawPolygon?.setMap(null);
    setDrawPolygon(null);
    setAreaInfo(null);
  };

  const handleFeatureNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeatureName(e.target.value);
  };

  function submitHandler() {
    if (!featureName) {
      toast.error('구역명을 입력해주세요.');
      document.getElementById('_featureName')?.focus();
      return;
    }

    const newPolygon = {
      type: 'FeatureCollection',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [[coords]],
      },
      properties: {
        contract_yn: true,
        feature_name: featureName,
      },
    };

    putNewPolygon(newPolygon)
      .then((res) => {
        const feature = res.data.features[0];
        drawPolygon?.setMap(null);
        setDrawPolygon(null);
        setAreaInfo(null);

        map.data.addGeoJson(feature, { idPropertyName: 'id' });
        setAreaInfo(feature.properties);

        map?.data.overrideStyle(feature, {
          strokeColor: '#EF233C',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: '#BEF264',
          fillOpacity: 0.5,
        });
      })
      .then(() => {
        toast.success('신규 구역이 추가되었습니다.');
      })
      .catch((err) => console.log('polygon submit err', err));
  }

  // 폴리곤 좌표 구하기
  useEffect(() => {
    featureNameRef.current?.focus();
    if (drawPolygon) {
      const polygonPaths = drawPolygon?.getPath().getArray().map((el) => {
        return [el.lng(), el.lat()];
      });

      if (polygonPaths) {
        const firstElement = polygonPaths.find((element) => element !== undefined);
        if (firstElement) {
          polygonPaths.push(firstElement);
        }

        const geometry = {
          geometry: {
            type: 'MultiPolygon',
            coordinates: [[polygonPaths]],
          },
        };

        getFeatureName(geometry).then((res: any) => {
          if(res.data && res.data.address_components) {
            const addressArray = res.data.address_components;
            const shortNames = addressArray.slice(0, 2).map((obj: any) => obj.short_name);
  
            setFeatureName(`${shortNames[1]} ${shortNames[0]}`);
            setAddress(res.data.formatted_address);
          } else {
            setFeatureName('')
            setAddress('')
          }

        });
        setCoords(polygonPaths);
      }
    }
  }, [drawPolygon]);

  const serviceInfo = () => {
    toast('구역 등록 후 수정 가능합니다.', {
      icon: 'ℹ️',
    });
  };

  if (drawPolygon) {
    return (
      <>
        <p className="text-lg font-bold">신규 구역 등록</p>
        <div className="line"></div>
        <form className="flex flex-col">
          <div className="mt-3 area-label">
            <span>구역명</span>
            <input
              id="_featureName"
              type="text"
              className="mr-1 text-right transition area-input focus:border-blue-500 focus:border-b-2"
              value={featureName}
              onChange={handleFeatureNameChange}
              placeholder="구역명을 입력해주세요."
              ref={featureNameRef}
            />
          </div>
          <p className="mt-2 mr-1 text-sm text-right text-neutral-500">{address}</p>
          {/* <p className="area-label">
            <span>소유자</span>
            <span>-</span>
          </p>
          <p className="area-label">
            <span>매입가</span>
            <span>-</span>
          </p> */}
          <div className="mt-3 line"></div>
          <label className="area-label">
            <span>생성자</span>
            <div className="area-input-wrap">{userData?.nickname}</div>
          </label>
          <label className="area-label">
            <span>사용여부</span>
            <div className="items-center area-input-wrap" onClick={serviceInfo}>
              <input type="checkbox" className="area-toogle-slide" checked={true} readOnly />
              <span className="area-toogle-btn" />
            </div>
          </label>
          <label className="area-label">
            <span>매매제한/해제</span>
            <div className="items-center area-input-wrap" onClick={serviceInfo}>
              <input type="checkbox" className="area-toogle-slide" checked={true} readOnly />
              <span className="area-toogle-btn" />
            </div>
          </label>
          {/* <label className="area-label">
            <span>Updated By</span>
            <div className="area-input-wrap">
              <span>-</span>
            </div>
          </label>
          <label className="area-label">
            <span>Updated At</span>
            <div className="area-input-wrap">
              <span>-</span>
            </div>
          </label> */}
          <div className="mb-4 line"></div>
          <div className="flex items-center justify-between">
            <button type="button" className="relative inline-flex items-center justify-center w-1/3 text-base text-red-400 bg-white border-red-300 shadow btn hover:bg-red-500 hover:text-white" onClick={removePolygon}>
              취소
            </button>
            <button type="button" className="relative inline-flex items-center justify-center w-1/3 text-base bg-white shadow btn text-neutral-800 group border-neutral-300 hover:bg-gradient-to-tr hover:from-neutral-900 hover:to-neutral-800 hover:text-white" onClick={submitHandler}>
              저장
            </button>
          </div>
        </form>
      </>
    );
  } else {
    return null;
  }
}

export default NewAreaInfo;
