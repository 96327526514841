import Layout from 'components/Layout/Layout';
import ADView from 'components/Dashboard/ADView';

function ADViewsPage() {
  return (
    <Layout title="광고 조회수 분석">
      <ADView />
    </Layout>
  );
}

export default ADViewsPage;
