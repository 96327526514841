import Modal from 'components/common/Modal';
import { UserType } from 'types/auth.types';
import { sandEmailAPI } from 'api/member';
import { toast } from 'react-hot-toast';

interface ModalProps {
  userData: UserType | null;
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<any>>;
}

function SandMailModal({ userData, show, setModalShow }: ModalProps) {

  const handleSandMail= () => {
    setModalShow(false);

    sandEmailAPI(userData?.email!).then(() => {
      toast.success('메일이 발송되었습니다.');
    });
  };

  return (
    <Modal show={show} setModalShow={setModalShow}>
      <div className="px-8 py-5 min-w-[450px]">
        <h3 className="text-xl font-bold dark:text-gray-400">비밀번호 재설정 메일 발송</h3>
        <p className='mt-1 text-sm text-neutral-500'>회원 비밀번호 재설정을 위한 인증메일 발송 기능입니다.</p>
          <div className='my-5 text-lg'>
            <span className='text-blue-400 text-bold'>{userData?.nickname}</span>
            {' '}회원님의{' '}
            <span className='text-blue-400 text-bold'>{userData?.email}</span>{' '}로 메일을 발송합니다.
          </div>
          <button
          type="button"
          className="relative inline-flex items-center justify-center w-full mt-2 text-base bg-white shadow btn text-neutral-800 group border-neutral-300 hover:bg-gradient-to-tr hover:from-neutral-900 hover:to-neutral-800 hover:text-white"
          onClick={handleSandMail}
        >
          인증메일발송
        </button>
      </div>
    </Modal>
  );
}

export default SandMailModal;
